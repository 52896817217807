.case {
  margin-bottom: 126px;
  min-height: 949px; }
  .case__content {
    max-width: 1365px;
    padding-left: 122px;
    padding-right: 114px;
    margin: 0 auto; }
  .case__content-filter {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin: 0 auto; }
  .case__content-filter-item {
        cursor: pointer;
        font-weight: bold;
        font-size: 24px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease; }
  .case__content-filter-item-divider {
          height: 20px;
          margin: 0 36px;
          border-left: 1px solid rgba(0, 0, 0, 0.24); }
  .case__content__cardSlides {
      margin: 90px auto; }
  .case__content__cardSlides__slide {
        display: grid !important;
        grid-template-columns: repeat(auto-fill, 279px);
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
  .case__content__cardSlides__slide__card {
          cursor: pointer;
          padding: 16px 16px 44px 15px;
          -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
          background-color: #ffffff;
          text-align: center;
          width: 240px;
          height: 288px;
          margin-bottom: 36px; }
  .case__content__cardSlides__slide__card-img {
            background: #d8d8d8; }
  .case__content__cardSlides__slide__card-img img {
              width: 208px; }
  .case__content__cardSlides__slide__card-title {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #727272;
            margin-top: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical; }
  .modal-img {
  width: 100%; }
  .modal-img img {
    width: 100%; }
  .modal-desc {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #0a131a; }
  .ant-modal-title {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #0a131a !important; }
  .ant-carousel .slick-dots li.slick-active button {
  background-image: -webkit-gradient(linear, left top, right top, from(#3acfff), to(#0074ba));
  background-image: linear-gradient(to right, #3acfff, #0074ba);
  margin-top: 16px !important;
  height: 11px !important;
  border-radius: 5.5px; }
  .ant-carousel .slick-dots li button {
  margin-top: 16px !important;
  height: 11px !important;
  border-radius: 5.5px;
  background: #292727; }
  @media only screen and (max-width: 430px) {
  .case {
    padding-top: 86px;
    min-height: 480px; }
    .case__content {
      text-align: center;
      display: block;
      padding-left: 0px;
      padding-right: 0px; }
      .case__content-filter-item {
        font-size: 16px; }
        .case__content-filter-item-divider {
          margin: 0 18px; }
      .case__content__cardSlides {
        margin: 20px auto; }
        .case__content__cardSlides__slide {
          display: grid !important;
          grid-template-columns: repeat(auto-fill, 260px);
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
          .case__content__cardSlides__slide__card {
            cursor: pointer;
            padding: 16px 16px 0px 15px;
            -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
            background-color: #ffffff;
            text-align: center;
            margin-bottom: 25px; }
            .case__content__cardSlides__slide__card-img {
              background: #d8d8d8; }
              .case__content__cardSlides__slide__card-img img {
                width: 60px;
                max-height: 57px;
                margin: 0 auto; }
            .case__content__cardSlides__slide__card-title {
              font-family: MicrosoftJhengHei;
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #727272;
              margin-top: 12px; } }
