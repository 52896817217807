/* gradient fallbacks taken from csstricks.com */
#banner {
  height: calc(100vh - 60px);
  background-color: blue;
  /* SVG fallback for IE 9 (could be data URI, or could use filter) */
  /* Safari 4, Chrome 1-9, iOS 3.2-4.3, Android 2.1-3.0 */
  /* Safari 5.1, iOS 5.0-6.1, Chrome 10-25, Android 4.0-4.3 */
  /* Firefox 3.6 - 15 */
  /* Opera 11.1 - 12 */
  /* Opera 15+, Chrome 25+, IE 10+, Firefox 16+, Safari 6.1+, iOS 7+, Android 4.4+ */
  background-image: -webkit-gradient(linear, left top, right top, from(#000), to(#0a131a));
  background-image: linear-gradient(to right, #000, #0a131a);
  margin: 0px;
  overflow: hidden; }
#banner canvas {
    opacity: 0.3; }
