.contact {
  width: 100%;
  position: absolute;
  height: 441px;
  margin: 0 auto; }
  .contact-title {
    text-align: center;
    padding-top: 90px;
    padding-bottom: 42px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff; }
  .contact__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .contact__content-logo {
      margin-right: 74px; }
  .contact__content-logo img {
        width: 148px; }
  .contact__content-info {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      color: #ffffff; }
  .contact__content-info ul {
        list-style-type: none; }
  @media only screen and (max-width: 429px) {
  .contact {
    padding-top: 86px;
    min-height: 498px; }
    .contact-title {
      padding-top: 38px; }
    .contact__content {
      display: block;
      padding: 12px; }
      .contact__content-info {
        font-size: 14px; }
      .contact__content-logo {
        text-align: center;
        margin-right: 0px;
        margin-bottom: 36px; }
        .contact__content-logo img {
          width: 82px; } }
