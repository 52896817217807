.about {
  text-align: center;
  margin-bottom: 126px; }
  .about__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1365px;
    margin: 0 auto;
    padding-left: 122px;
    padding-right: 114px; }
  .about__content-text {
      max-width: 50%;
      text-align: left;
      margin-right: 30px;
      color: #0a131a; }
  .about__content-text p {
        margin-bottom: 12px; }
  .about__content-img {
      width: 50%; }
  .about__content-img img {
        width: 100%; }
  @media only screen and (max-width: 430px) {
  .about {
    padding-top: 86px;
    margin-bottom: 0px; }
    .about__content {
      text-align: center;
      display: block;
      padding-left: 0px;
      padding-right: 0px; }
      .about__content-text {
        max-width: 672px;
        text-align: left;
        margin-right: 0px;
        padding: 21px 15px;
        color: #0a131a; }
      .about__content-img {
        width: 100%; }
        .about__content-img img {
          width: 100%; } }
