body {
  margin: 0 auto;
  background: white;
  font-family: Microsoft JhengHei; }
html {
  scroll-behavior: smooth; }
.app {
  font-family: 'Noto Sans TC', 'Microsoft JhengHei', 'PingFang TC'; }
.app__header {
    background: #080f14;
    padding: 12px;
    position: fixed;
    width: 100%;
    z-index: 99; }
.app__header__container {
      max-width: 1365px;
      margin: 0 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
.app__header-icon {
      margin-right: 16px;
      padding-left: 86px; }
.app__header-icon img {
        width: 36px;
        height: 36px; }
.app__header-title {
      font-size: 18px;
      display: none; }
.app__header__link {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      max-width: 800px;
      margin: 0 auto; }
.app__header__link-item {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        line-height: 36px;
        color: white;
        text-decoration: none;
        margin-right: 100px;
        font-weight: bold;
        font-size: 18px;
        -webkit-box-flex: 2;
            -ms-flex: 2;
                flex: 2; }
.app__header__link-item--active {
          text-shadow: 2px 2px 3px #434a54; }
.app__header__link-item:hover {
          color: white;
          text-shadow: 2px 2px 3px #434a54; }
.app__header__link .acive {
        color: #ffffff; }
.app__header__link .normal {
        color: #a7a7a7; }
.app__header-select {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; }
.menu__mobile__btn {
  display: none;
  font-size: 24px; }
.ant-menu {
  background: #080f14 !important; }
.ant-menu-horizontal > .ant-menu-item a {
  color: #fff !important;
  font-size: 20px !important; }
.ant-menu-horizontal {
  border-bottom: 1px solid #080f14 !important; }
@media only screen and (max-width: 430px) {
  .app__header__link {
    display: block; }
  .app__header {
    height: 86px;
    padding: 22px 30px; }
    .app__header__container {
      margin: 0 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    .app__header-icon {
      margin-right: 16px;
      padding-left: 0px; }
      .app__header-icon img {
        width: 34px;
        height: 42px; }
    .app__header-title {
      color: #fff;
      font-size: 18px;
      display: block; }
    .app__header__link-item {
      display: block; }
  .ant-drawer-header {
    background-color: #080f14 !important;
    border-bottom: 1px solid #080f14 !important;
    color: #ffffff !important; }
  .ant-drawer-title {
    color: #fff !important; }
  .ant-drawer-content {
    background-color: #000 !important; }
  .header__desktop {
    display: none; }
  .header__mobile {
    display: block; }
  .menu__mobile__btn {
    display: block;
    -webkit-box-flex: 2;
        -ms-flex: 2;
            flex: 2; } }
.home {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #ffffff; }
.home__title {
    color: #666;
    text-shadow: unset;
    font-weight: bold;
    font-size: 24px; }
.home__title--active {
      color: #0087dc;
      text-shadow: 2px 2px 3px #434a54; }
.home__btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 24px; }
.home__banner {
    padding-top: 60px; }
.home__banner-logo {
      position: absolute;
      top: 30vh;
      text-align: center;
      width: 100%; }
.home__banner-logo-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
.home__banner-logo-title {
        padding-left: 20px;
        font-size: 35px;
        color: #4693d0; }
.home__banner-logo-title img {
          width: 228px; }
.home__banner-logo-title-eng {
          font-size: 10px;
          text-align: left; }
.home__banner-logo-desc {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.29px;
        color: #c6e6ff; }
.home__banner-logo-arrow {
        padding-top: 32px;
        cursor: pointer; }
.home__banner-logo-arrow-color {
          fill: #c6e6ff; }
.home__contact {
    bottom: 0; }
.title {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0a131a;
  width: 100%;
  text-align: center;
  padding-top: 90px;
  margin-bottom: 42px; }
.container {
  height: 100%; }
@media only screen and (max-width: 430px) {
  .title {
    padding-top: 0px; }
  .home {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #ffffff; }
    .home__title {
      color: #666;
      text-shadow: unset;
      font-weight: bold;
      font-size: 24px; }
      .home__title--active {
        color: #0087dc;
        text-shadow: 2px 2px 3px #434a54; }
    .home__btn {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin-top: 24px; }
    .home__banner {
      padding-top: 60px; }
      .home__banner-logo {
        position: absolute;
        top: 30vh;
        text-align: center;
        width: 100%; }
        .home__banner-logo-block {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
        .home__banner-logo-title {
          padding-left: 0px;
          font-size: 35px;
          color: #4693d0; }
          .home__banner-logo-title-eng {
            font-size: 10px;
            text-align: left; }
        .home__banner-logo-desc {
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.29px;
          color: #c6e6ff; }
        .home__banner-logo-kv img {
          width: 100%; }
        .home__banner-logo-arrow {
          padding-top: 12px;
          cursor: pointer; }
          .home__banner-logo-arrow-color {
            fill: #c6e6ff; }
    .home__contact {
      bottom: 0; } }
.about {
  padding: 12px; }
.about__title {
    color: #666;
    font-weight: bold;
    font-size: 24px; }
.about__avatar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 12px; }
.about__avatar-item {
      margin-right: 6px !important; }
.topic {
  padding: 12px; }
.topic__title {
    color: #666;
    font-weight: bold;
    font-size: 24px; }
.topic__block {
    margin-top: 12px; }
