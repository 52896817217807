.service {
  margin-bottom: 126px;
  padding-bottom: 90px;
  background-color: #f8fafe; }
  .service__content {
    max-width: 1365px;
    padding-left: 122px;
    padding-right: 114px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, 370px);
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .service__content__card {
      width: 360px;
      height: 150px;
      margin: 0px 22px 24px 22px;
      padding: 25px 16px 24px 19px;
      -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 360px; }
  .service__content__card__content-title {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #0a131a;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-left: 12px; }
  .service__content__card__content-desc {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3f627c;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding: 12px; }
  .service .ant-card-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 12px !important;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .service .ant-card-cover img {
    width: 100px; }
  .service .ant-card-meta-title {
    white-space: initial;
    max-width: 232.67px;
    font-size: 24px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #0a131a; }
  @media only screen and (max-width: 429px) {
  .service {
    padding-top: 86px;
    margin-bottom: 0px;
    padding-bottom: 90px;
    background-color: #f8fafe; }
    .service__content {
      max-width: 1365px;
      padding-left: 0px;
      padding-right: 0px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(auto-fill, 320px);
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .service__content__card {
        width: 100%;
        height: 150px;
        margin: 0px 22px 24px 0px;
        padding: 25px 16px 24px 19px;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
        .service__content__card__content-title {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #0a131a; }
        .service__content__card__content-desc {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #3f627c; } }
